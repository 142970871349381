import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '../public/assets/css/style.css'
import '@mdi/font/css/materialdesignicons.css'
import 'babel-polyfill'
import Vuebar from 'vuebar'
import '@/validation/validation-config'
import { ValidationProvider } from 'vee-validate'
import ldUserPlugin from './plugins/ld-user-plugin'
import { LDEvaluationDetail } from 'launchdarkly-js-client-sdk'
import { datadogRum } from '@datadog/browser-rum'
import { startDatadog } from './plugins/datadog'
import ConfigurationController from './api/configuration-controller'
import { TemplateStatus } from './common/enums'
import PortalOffline from './PortalOffline.vue'
import { useLdClient } from './plugins/ld-user-plugin'
import { IClientTemplate } from './models/client/interfaces/client-template'

startDatadog()

Vue.component('ValidationProvider', ValidationProvider)
Vue.use(Vuetify)
Vue.use(Vuebar)

Vue.config.productionTip = false

const vuetifyConfig = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
})

Vue.use(ldUserPlugin, {
  envKey: process.env.VUE_APP_LD_CLIENT_ID,
  store,
  ldOptions: {
    streaming: true,
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key: string, detail: LDEvaluationDetail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value)
        },
      },
    ],
  },
})

function loadPortalOfflineApp(clientSettings: IClientTemplate | null) {
  return new Vue({
    render: (h) =>
      h(PortalOffline, {
        props: { clientSettings: clientSettings },
      }),
  }).$mount('#app')
}

function loadMainApp(clientSettings: IClientTemplate | null) {
  return new Vue({
    router,
    store,
    vuetify: vuetifyConfig,
    render: (h) =>
      h(App, {
        props: { clientSettings: clientSettings },
      }),
  }).$mount('#app')
}

ConfigurationController.GetConfiguration().then((clientSettings) => {
  const ldClient = useLdClient()
  ldClient
    ?.waitForInitialization()
    .then(() => {
      const isfeatureFlagActive = Boolean(
        ldClient?.variation('fnol-499-add-offline-page-to-portal')
      )
      if (
        isfeatureFlagActive &&
        (!clientSettings || clientSettings.status !== TemplateStatus.Online)
      ) {
        loadPortalOfflineApp(clientSettings)
      } else {
        loadMainApp(clientSettings)
      }
    })
    // If LD fails, we need to fall back to main app, this will show pages that are meant to be offline, but that
    // is better than showing no pages at all. The goal will be to remove this FF ASAP once we have proved the logic works
    .catch(() => {
      loadMainApp(clientSettings)
    })
})

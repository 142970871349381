import { render, staticRenderFns } from "./EmergencySelection.vue?vue&type=template&id=113697ed&scoped=true&"
import script from "./EmergencySelection.vue?vue&type=script&lang=ts&"
export * from "./EmergencySelection.vue?vue&type=script&lang=ts&"
import style0 from "./EmergencySelection.vue?vue&type=style&index=0&id=113697ed&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113697ed",
  null
  
)

export default component.exports
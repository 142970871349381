







import { Component, Prop, Vue } from 'vue-property-decorator'
import { IClientTemplate } from './models/client/interfaces/client-template'

@Component
export default class PortalOffline extends Vue {
  @Prop()
  public clientSettings: IClientTemplate
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"role":"region","aria-label":"Emergency selection"}},[(_vm.emergencyDisplayList.length > 0)?_c('div',{staticClass:"emergency"},[_c('div',{staticClass:"add-emergency pa-2",style:({ background: _vm.clientTemplate.secondaryColor })},[_c('v-row',{staticClass:"flex-wrap ma-0"},[_c('v-col',{staticClass:"mb-2 pa-0 pl-2",attrs:{"cols":"12"}},[_c('h2',{staticClass:"emergency-description"},[_vm._v(" What best describes your situation? ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"emergency-type",attrs:{"role":"navigation","aria-label":"emergency selection navigation"}},[_c('ValidationProvider',{ref:"emergencyRef",attrs:{"name":"emergency","rules":"required","custom-messages":{ required: 'Please select an emergency' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('v-list',{model:{value:(_vm.selectedEmergencyIds),callback:function ($$v) {_vm.selectedEmergencyIds=$$v},expression:"selectedEmergencyIds"}},[_vm._l((_vm.emergencyDisplayList),function(emergency,index){return [(_vm.hasMoreEmergency || index < _vm.topEmergencyLength)?_c('v-list-item',{key:index},[_c('v-list-item-content',{staticClass:"pa-2 elevation-1",class:{
                        'select-emergency': _vm.isEmergencySelected(emergency.id),
                      },style:({
                        borderColor: _vm.clientTemplate.complementaryColor,
                      }),attrs:{"role":"button","aria-label":((emergency.description) + " " + (_vm.isEmergencySelected(emergency.id)
                          ? 'selected'
                          : 'unselected')),"tabindex":0},on:{"click":function($event){return _vm.onEmergencySelection(emergency, validate)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onEmergencySelection(emergency, validate)}}},[_c('v-avatar',[_c('img',{staticClass:"avatar-image",attrs:{"src":emergency.fileURL
                              ? emergency.fileURL
                              : '/img/emergency.svg',"alt":""}})]),_c('v-list-item-title',[_vm._v(_vm._s(emergency.description))])],1)],1):_vm._e()]}),(_vm.emergencyDisplayList.length > 0 && !_vm.hasMoreEmergency)?_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-2 elevation-1 add-other-emergency",style:({
                      borderColor: _vm.clientTemplate.complementaryColor,
                    }),attrs:{"role":"button","tabindex":0},on:{"click":_vm.clickMoreEmergencies,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.clickMoreEmergencies.apply(null, arguments)}}},[_c('v-avatar',[_c('v-icon',{style:({ color: _vm.clientTemplate.primaryColor })},[_vm._v("add_circle_outline")])],1),_c('v-list-item-title',{style:({
                        color: _vm.clientTemplate.primaryColor + '!important',
                      })},[_vm._v("More Emergencies")])],1)],1):_vm._e()],2),(errors.length > 0)?_c('div',{staticClass:"pb-2 mb-2"},[_c('div',{staticClass:"pa-2 lighten-4"},[_c('p',{staticClass:"error-class",style:({ color: _vm.clientTemplate.complementaryColor })},[_vm._v(" "+_vm._s(errors[0])+" ")])])]):_vm._e()]}}],null,false,1635309536)})],1)])],1)],1),_vm._l((_vm.getEmergenciesWithDetail),function(selectedEmergency,index){return _c('div',{key:index + selectedEmergency.emergencyTypeDesc,staticClass:"emergency-details pa-4 mt-4",style:({ background: _vm.clientTemplate.secondaryColor }),attrs:{"id":("selected-emergency-" + (selectedEmergency.emergencyTypeId))}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"flex-wrap ma-0"},[_c('v-col',{attrs:{"cols":"12 pa-0"}},[_c('h2',{staticClass:"type-description float-left pt-2",attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(selectedEmergency.emergencyTypeDesc)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-0 float-right",style:({ color: _vm.clientTemplate.complementaryColor }),attrs:{"icon":"","text":"","tabindex":"0","label":("Remove " + (selectedEmergency.emergencyTypeDesc) + " emergency"),"aria-label":("Remove " + (selectedEmergency.emergencyTypeDesc) + " emergency")},on:{"click":function($event){return _vm.removeSelectedEmergency(selectedEmergency)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)],1),_c('v-col',{attrs:{"cols":"12 pa-0"}},[_c('ValidationProvider',{attrs:{"name":("Emergency Detail " + index),"rules":"required","custom-messages":{
                required: 'Emergency Detail is required',
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select-custom',{ref:'emergency' + index,refInFor:true,class:'required emergency-select-' + index,attrs:{"id":'emergency-select-' + index,"items":_vm.getSelectedEmergencyDetail(selectedEmergency),"item-value":"id","item-text":"description","aria-label":"Emergency Detail","return-object":"","required":"","error-messages":errors,"single-line":""},on:{"change":function($event){return _vm.onChangeEmergencyDetail(selectedEmergency)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-list-item-content',{staticClass:"v-select__selection",attrs:{"id":'selected-emergency' + index,"aria-haspopup":"listbox","role":"button","aria-expanded":"false","aria-label":'Emergency Detail ' + data.item.description}},[_vm._v(" "+_vm._s(data.item.description)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"id":data.item.id,"tabindex":"0","role":"text","aria-label":_vm.getSelectedItem(
                        data.item.description,
                        selectedEmergency.emergencyDetail
                      )
                        ? data.item.description + 'list item selected'
                        : data.item.description}},[_vm._v(" "+_vm._s(data.item.description)+" ")])]}}],null,true),model:{value:(selectedEmergency.emergencyDetail),callback:function ($$v) {_vm.$set(selectedEmergency, "emergencyDetail", $$v)},expression:"selectedEmergency.emergencyDetail"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12 pa-0"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('ValidationProvider',{attrs:{"name":("First Noticed " + index),"rules":"required","custom-messages":{
                  required: 'The First Noticed date is required',
                }}},[_c('DateTimePicker',{ref:"dateTimePicker",refInFor:true,attrs:{"date-time":selectedEmergency.firstNoticedAt,"is-static-location":false,"place-holder-text":"First Noticed","max-date":_vm.maxFirstNoticedAtDate,"is-current-time":true,"is-validation-required":true,"index":index,"initial-date-time-control-option-msg":"When did you first notice this issue?"},on:{"update:dateTime":function($event){return _vm.$set(selectedEmergency, "firstNoticedAt", $event)},"update:date-time":function($event){return _vm.$set(selectedEmergency, "firstNoticedAt", $event)}},model:{value:(selectedEmergency.firstNoticedAt),callback:function ($$v) {_vm.$set(selectedEmergency, "firstNoticedAt", $$v)},expression:"selectedEmergency.firstNoticedAt"}})],1),(
                  !errors['dateTimePicker'] &&
                  errors[("First Noticed " + index)]
                )?_c('div',{staticClass:"error-msg"},[_vm._v(" "+_vm._s(errors[("First Noticed " + index)][0])+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }